<template>
  <div class="image-container">
    <img :src="file.path" :alt="file.name || file.path" srcset="" />
  </div>
</template>

<script>
export default {
  props: { file: { required: true } },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style scoped>
.image-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.image-container img {
  max-width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
